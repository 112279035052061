<template>
  <div id="app">
    <a href="#main" class="sr-only" id="skip-link">Skip to main content</a>
    <header>
      <span class="emoji">
        <img :src="'https://emojicdn.elk.sh/❄️'" width="80" alt="" />
      </span>
      <div class="container">
        <div class="menu">
          <a href="https://www.linkedin.com/in/aluini/" target="_blank">LinkedIn</a>
          <a href="https://github.com/aluini" target="_blank">GitHub</a>
        </div>
      </div>
    </header>
    <main role="main" id="main">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </main>
    <footer>
      <div class="container">
        2019-{{currentYear}} - Version WINTER24 - Developed using vueJS framework and deployed on Netlify
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      currentYear: new Date().getFullYear()
    }
  },
}
</script>

<style lang="scss" src="@/assets/scss/main.scss"></style>
<style lang="scss" scoped>
  [id="skip-link"] {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    text-align: center;
    padding: 1rem;
    background-color: #fff;
    color: #000;
  }

  .overlay{
    z-index: 999;
    position: fixed;
    &:after{
      content:"";
      display: block;
      background: var(--detail);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.6;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter, .fade-leave-active {
    opacity: 0;
    @media (prefers-reduced-motion){
      opacity: 1;
    }
  }

  .slide-enter-active, .slide-leave-active {
  transition: transform .5s;
  }
  .slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateX(100%);
  }
</style>
